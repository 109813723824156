/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  transferlayerDetails: {
    id: "transferlayer.details",
    defaultMessage: "Details",
  },
  transferlayerPublishedOn: {
    id: "transferlayer.published.on",
    defaultMessage: "Gepubliceerd op",
  },
  transferlayerCopyURL: {
    id: "transferlayer.copy.url",
    defaultMessage: "Kopiëer url",
  },
  transferlayerModify: {
    id: "transferlayer.modify",
    defaultMessage: "Bewerken",
  },
  transferlayerActiveLayers: {
    id: "transferlayer.active.layers",
    defaultMessage: "Actieve lagen",
  },
  transferlayerEdit: {
    id: "transferlayer.edit",
    defaultMessage: "Bewerk",
  },
  transferlayerName: {
    id: "transferlayer.name",
    defaultMessage: "Naam",
  },
  transferlayerURL: {
    id: "transferlayer.url",
    defaultMessage: "URL",
  },
  transferlayerUsername: {
    id: "transferlayer.username",
    defaultMessage: "Gebruikersnaam (indien beveiligd)",
  },
  transferlayerPassword: {
    id: "transferlayer.password",
    defaultMessage: "Paswoord (indien beveiligd)",
  },
  transferlayerMinZoom: {
    id: "transferlayer.min.zoom",
    defaultMessage: "Minimum zoom level",
  },
  transferlayerFilterBuilder: {
    id: "transferlayer.filter.builder",
    defaultMessage: "Filter builder",
  },
  transferlayerProperty: {
    id: "transferlayer.property",
    defaultMessage: "Property",
  },
  transferlayerCancel: {
    id: "transferlayer.cancel",
    defaultMessage: "Annuleren",
  },
  transferlayerTen: {
    id: "transferlayer.ten",
    defaultMessage: "Ten",
  },
  transferlayerTwenty: {
    id: "transferlayer.twenty",
    defaultMessage: "Twenty",
  },
  transferlayerThirty: {
    id: "transferlayer.thirty",
    defaultMessage: "Thirty",
  },
  transferlayerMultiple: {
    id: "transferlayer.multiple",
    defaultMessage: "Disjoint Equals DWithin Beyond Intersects Touches Crosses Within Contains Overlaps BBOX",
  },
  transferlayerNew: {
    id: "transferlayer.new",
    defaultMessage: "Nieuw",
  },
  transferlayerWMSWFS: {
    id: "transferlayer.wms.wfs",
    defaultMessage: "WMS/WFS",
  },
  transferlayerWFS: {
    id: "transferlayer.wfs",
    defaultMessage: "Wat is een WFS?",
  },
  transferlayerWFSExplanation: {
    id: "transferlayer.wfs.explanation",
    defaultMessage:
      "Een Web Feature Service (WFS) is een overdrachtdienst voor het opvragen en aanleveren van geografische vectoriële data. De communicatie en het aanleveren van de gegevens gebeurt door middel van XML.",
  },
  transferlayerGMLExplanation: {
    id: "transferlayer.gml.explanation",
    defaultMessage: "De gegevens worden aangeleverd onder de vorm van een voor geografische gegevens specifiek schema, beter bekend als GML (Geography Markup Language).",
  },
  transferlayerWMS: {
    id: "transferlayer.wms",
    defaultMessage: "Wat is een WMS?",
  },
  transferlayerWMSExplanation: {
    id: "transferlayer.wms.explanation",
    defaultMessage:
      "Web Map Service (WMS) biedt een standaard interface voor het aanvragen van een geospatiale kaartafbeelding. Het voordeel hiervan is dat WMS-clients afbeeldingen van meerdere WMS-servers kunnen opvragen en deze vervolgens kunnen combineren tot één weergave voor de gebruiker. De standaard garandeert dat deze afbeeldingen allemaal over elkaar heen kunnen worden gelegd zoals ze in werkelijkheid zouden zijn.",
  },
  transferlayerAddWFS: {
    id: "transferlayer.add.wfs",
    defaultMessage: "WFS toevoegen",
  },
  transferlayerAddWMS: {
    id: "transferlayer.add.wms",
    defaultMessage: "WMS toevoegen",
  },
  transferlayerValidate: {
    id: "transferlayer.validate",
    defaultMessage: "Valideren",
  },
  transferlayerAdd: {
    id: "transferlayer.add",
    defaultMessage: "Toevoegen",
  },
  transferlayerFeatureType: {
    id: "transferlayer.feature.type",
    defaultMessage: "FeatureType",
  },
  transferlayerLessThan: {
    id: "transferlayer.less.than",
    defaultMessage: "minder dan",
  },
  transferlayerMoreThan: {
    id: "transferlayer.more.than",
    defaultMessage: "meer dan",
  },
  transferlayerLessAndEqualTo: {
    id: "transferlayer.less.and.equal.to",
    defaultMessage: "minder en gelijk aan",
  },
  transferlayerMoreAndEqualTo: {
    id: "transferlayer.more.and.equal.to",
    defaultMessage: "meer en gelijk aan",
  },
  transferlayerEqualTo: {
    id: "transferlayer.equal.to",
    defaultMessage: "gelijk aan",
  },
  transferlayerNotEqualTo: {
    id: "transferlayer.not.equal.to",
    defaultMessage: "niet gelijk aan",
  },
  transferlayerLike: {
    id: "transferlayer.like",
    defaultMessage: "zoals",
  },
  transferlayerBetween: {
    id: "transferlayer.between",
    defaultMessage: "tussen",
  },
  transferlayerNotNull: {
    id: "transferlayer.not.null",
    defaultMessage: "niet NULL",
  },
  transferlayerIsNil: {
    id: "transferlayer.is.nil",
    defaultMessage: "idkwhatnilis",
  },
  transferlayerNotTranslatedYet: {
    id: "transferlayer.not.translated.yet",
    defaultMessage: "NOG TE VERTALEN:",
  },
  transferlayerStyleGeographicType: {
    id: "transferlayer.style.geographic.type",
    defaultMessage: "Stijl voor geografisch type {type}",
  },
  transferlayerBorderColor: {
    id: "transferlayer.border.color",
    defaultMessage: "Randkleur",
  },
  transferlayerFillColor: {
    id: "transferlayer.fill.color",
    defaultMessage: "Vulkleur",
  },
  transferlayerTransparency: {
    id: "transferlayer.transparency",
    defaultMessage: "Doorzichtbaarheid",
  },
  transferlayerFiltersProperty: {
    id: "transferlayer.filters.property",
    defaultMessage: "Eigenschap filters (waarschuwing dat kennis van data in wfs aangeraden is)",
  },
  transferlayerPropertyDutch: {
    id: "transferlayer.property.dutch",
    defaultMessage: "Eigenschap",
  },
  transferlayerOperator: {
    id: "transferlayer.operator",
    defaultMessage: "Operator",
  },
  transferlayerValue1: {
    id: "transferlayer.value.1",
    defaultMessage: "Waarde 1",
  },
  transferlayerValue2: {
    id: "transferlayer.value.2",
    defaultMessage: "Waarde 2",
  },
  transferlayerAddFilter: {
    id: "transferlayer.add.filter",
    defaultMessage: "Voeg filter toe",
  },
  transferlayerLayers: {
    id: "transferlayer.layers",
    defaultMessage: "Layers",
  },
});
