import axios from "axios";
import {
  MapListType,
  LayerListObjectType,
  LayergroupListObjectType,
  LayerTableObjectType,
  LinkDataTableType,
  BaseLayerListObjectType,
  AnalyseConfig,
  PublicationListObjectType,
  SearchDateRange,
  PagingType,
  EditType,
  SpatialAnalysisRelations,
  SpatialAnalysisBufferUnit,
  PublicationEnrichment,
  FileMappingType,
  GeoFile,
  ConvertionTask,
} from "@orbit/geo-core-shared";
import { GridSortModelParams } from "@material-ui/x-grid";
import { DataLayerType } from "stores/DataLayerStore";

const { API_URL, MARTIN_URL } = window.env;

export const setDefaultHeader = (token) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

export const fetchMapData = async (): Promise<MapListType> => {
  const { data } = await axios.get(`${MARTIN_URL}index.json`);
  return data;
};

export const fetchLayerData = async (
  id: string,
): Promise<{
  formattedLayer: LayerListObjectType;
  table: { columns: any[]; rows: any[] };
  baseLayers: BaseLayerListObjectType[];
  esExists: boolean;
  esIndex: string;
}> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/layers/${id}`);

  return data;
};

export const getFiles = async (page: number, pageSize: number, convertionTaskId: string): Promise<{ files: GeoFile[]; amountOfFiles: number }> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/file/get-files/${convertionTaskId}/${page}/${pageSize}`);

  return data;
};

export const createLocationIndex = async (
  id: string,
): Promise<{
  success: boolean;
}> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/locationIndex/${id}`);

  return data;
};

export const removeFiles = async (files: string[]): Promise<ConvertionTask[]> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}geo-core/file/delete-files`, { data: files });

  return data;
};

export const updateLocationIndex = async (id: string, overwrite: boolean): Promise<{ success: boolean }> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/locationIndex/${id}/${overwrite}`);

  return data;
};

export const fetchTableData = async (id: string, paging: PagingType, sorting: GridSortModelParams): Promise<{ table: { columns: any[]; rows: any[]; count: number } }> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/table/${id}`, { paging, sorting });
  return data;
};

export const fetchLinkableLayers = async (): Promise<LayerTableObjectType[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/layers/linkable`);
  return data;
};

export const fetchLayersData = async (type?: DataLayerType): Promise<LayerListObjectType[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/layers`, { params: { type } });
  return data;
};

export const setDbPassword = async (password: string): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/setdbpass`, { password });
  return data;
};

export const downloadDataLayer = async (id: string, type: string, crs: number) => {
  const data = await axios.post(
    `${API_URL}geo-core/layers/download/${id}`,
    { type, toCrs: crs },
    {
      responseType: "blob",
    },
  );

  return data;
};

export const fetchBaseLayer = async (id: string): Promise<BaseLayerListObjectType> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/baselayers/${id}`);
  return data;
};
export const updateBaseLayer = async (id: string, updatedProperties: any): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await axios.put(`${API_URL}geo-core/baselayers/${id}`, updatedProperties);
    return data;
  } catch (error) {
    console.error("Error updating base layer:", error.message);
    throw error;
  }
};
export const createBaseLayer = async (createProperties: any): Promise<boolean> => {
  try {
    const {
      data: { data },
    } = await axios.post(`${API_URL}geo-core/baselayers`, createProperties);
    return data;
  } catch (error) {
    console.error("Error creating base layer:", error.message);
    throw error;
  }
};

export const fetchBaseLayers = async (): Promise<BaseLayerListObjectType[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/baselayers`);
  return data;
};

export const fetchPublicBaseLayers = async (): Promise<BaseLayerListObjectType[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/baselayers`);
  return data;
};

export const fetchAnalyseConfigs = async (): Promise<AnalyseConfig[]> => {
  const { data } = await axios.get(`${API_URL}geo-core/analyse/configs`);

  return data;
};

export const fetchPublication = async (id: string): Promise<{ data: PublicationListObjectType }> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/publication/${id}`);
  return data;
};

export const searchPublication = async (
  id: string,
  index: string,
  searchValue: string,
  features: {},
  relation: SpatialAnalysisRelations,
  ranges: SearchDateRange[],
  paging: PagingType,
  bufferValue: string,
  exactQuery: boolean,
): Promise<{ data: { items: any[]; total: number } }> => {
  const { data } = await axios.post(`${API_URL}geo-core/publication/search`, {
    id,
    searchValue,
    features,
    relation,
    index,
    ranges,
    // paging,
    ...(!isNaN(Number(bufferValue))
      ? {
          buffer: {
            value: bufferValue,
            unit: SpatialAnalysisBufferUnit.meters,
          },
        }
      : {}),
    exactQuery,
  });
  return data;
};
export const searchEnrichment = async (id: string, index: string, searchValue: string): Promise<{ data: { items: any[]; total: number } }> => {
  const { data } = await axios.post(`${API_URL}publication-enrichment/search`, {
    id,
    searchValue,
    index,
  });
  return data;
};

export const fetchPublicPublication = async (id: string): Promise<PublicationListObjectType> => {
  const {
    data: { data } = {},
  } = await axios.get(`${API_URL}geo-core/publication/viewer/${id}`);
  return data;
};

export const fetchPublications = async (): Promise<PublicationListObjectType[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/publication`);
  return data;
};

export const fetchEnrichments = async (): Promise<PublicationEnrichment[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}publication-enrichment/enrichments`);
  return data;
};

export const fetchNotifications = async (): Promise<any> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}notification`);
  return data;
};

export const deleteNotification = async (id): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}notification/${id}`);
  return data;
};

export const deleteNotifications = async (): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}notification/`);
  return data;
};

export const fetchLayergroupsData = async (): Promise<LayergroupListObjectType[]> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/layer-group`);
  return data;
};

export const fetchLayergroupData = async (
  id: string,
): Promise<{
  layerGroup: LayergroupListObjectType;
  baseLayers: BaseLayerListObjectType[];
}> => {
  const {
    data: { data },
  } = await axios.get(`${API_URL}geo-core/layer-group/${id}`);

  return data;
};

export const createNewLayergroup = async (layerIds, name): Promise<LayergroupListObjectType> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/layer-group`, {
    layerGroup: {
      layerIds,
      name,
    },
  });
  return data;
};

export const updateLayergroupName = async (id, name): Promise<LayergroupListObjectType> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/layer-group/${id}`, {
    name,
  });
  return data;
};

export const publishLayerGroup = async (id: string, config: { overwriteStyle?: boolean } = {}): Promise<LayergroupListObjectType> => {
  const {
    data: { data },
  } = await axios.put(`${API_URL}geo-core/layer-group/${id}`, {
    ...config,
  });
  return data;
};

export const pushFileMapping = async (
  fileMapping: FileMappingType,
): Promise<{
  formattedLayer: LayerListObjectType;
}> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/file/set-file-mapping`, fileMapping);
  return data;
};

export const loadFileCount = async (): Promise<{}> => {
  try {
    const {
      data: { data },
    } = await axios.get(`${API_URL}geo-core/file/file-count`);
    return data;
  } catch (error) {
    console.error("Error loading file count:", error.message);
    return 0;
  }
};

export const linkDataTable = async (linkData: LinkDataTableType): Promise<boolean> => {
  const {
    data: { data: { style } },
  } = await axios.post(`${API_URL}geo-core/link-data-table`, linkData);
  return style;
};

export const unlinkDataTable = async (id: string): Promise<boolean> => {
  const {
    data: { data: { style } },
  } = await axios.delete(`${API_URL}geo-core/unlink-data-table/${id}`);
  return style;
};

export const createPublication = async (name): Promise<PublicationListObjectType> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/publication`, {
    name,
  });
  return data;
};

export const updatePublication = async (id: string, type: EditType, newdata): Promise<PublicationListObjectType> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/publication/${id}`, {
    type,
    newdata,
  });
  return data;
};

export const deleteLayer = async (id): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}geo-core/layers/${id}`);
  return data;
};

export const deleteLayerGroup = async (id): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}geo-core/layer-group/${id}`);
  return data;
};

export const deleteBaselayer = async (id): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}geo-core/baselayers/${id}`);
  return data;
};

export const deletePublication = async (id): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.delete(`${API_URL}geo-core/publication/${id}`);
  return data;
};

export const updateDataLayer = async (id, updatedProperties): Promise<boolean> => {
  const {
    data: { data },
  } = await axios.post(`${API_URL}geo-core/layers/${id}`, updatedProperties);
  return data;
};

export const createDatalayer = async ({ type, data }: { type: DataLayerType; data: any }): Promise<ConvertionTask> => {
  const {
    data: { data: returnData },
  } = await axios.post(`${API_URL}geo-core/create-layer/${type}`, data);
  return returnData;
};
