import { AuthType, LatLngType, LoginData, RolePermission, VerifyToken } from "@orbit/geo-core-shared";
import AccessControl from "accesscontrol";
import jwt_decode from "jwt-decode";
import { action, computed, makeObservable, observable } from "mobx";
import { isHydrated, makePersistable } from "mobx-persist-store";
import { ROUTE_BASE, ROUTE_LOGIN, ROUTE_SELECT_PROFILE, ROUTE_SSO_SESSION_CALLBACK } from "routes/RouteList";
import { history } from "../history";
import { linkTokenToAxios, login, loginSSO, logout, setProfile, validatetoken } from "../services/auth";
import { setDefaultHeader } from "../services/geo-core";
import { firstLetterUpperCase } from "../utils";
import { DEFAULT_LOCALE } from "./../constants";
import MapStore from "./MapStore";
import UiStore from "./UiStore";

const AUTH_STRATEGIES_CONFIG = [
  { type: AuthType.USER_PASS, label: "Login", isSSO: false },
  { type: AuthType.KEYCLOAK, label: "Keycloak", isSSO: true },
  { type: AuthType.ACMIDM, label: "ACM/IDM", isSSO: true },
  { type: AuthType.POLICE, label: "SSO", isSSO: true },
];

const { API_URL, AUTH_STRATEGIES = "user-pass" } = window.env;

type canEditType = (rolePermissions: RolePermission | null, resource: string) => boolean;

type UserType = { firstName: string; lastName: string };

const canEdit: canEditType = (rolePermissions: RolePermission | null, resource) => {
  let granted: boolean = false;
  if (!rolePermissions) return granted;
  const { permissions, roleId } = rolePermissions;
  //@ts-ignore
  const access = new AccessControl(permissions);
  let action: string = "UPDATE";
  let crud: string[] = ["CREATE", "READ", "UPDATE", "DELETE"];
  if (crud.indexOf(action) > -1) granted = access.can(roleId)[action.toLowerCase() + "Any"](resource).granted;
  return granted;
};
const canCreate: canEditType = (rolePermissions: RolePermission | null, resource) => {
  let granted: boolean = false;
  if (!rolePermissions) return granted;
  const { permissions, roleId } = rolePermissions;
  //@ts-ignore
  const access = new AccessControl(permissions);
  let action: string = "CREATE";
  let crud: string[] = ["CREATE", "READ", "UPDATE", "DELETE"];
  if (crud.indexOf(action) > -1) granted = access.can(roleId)[action.toLowerCase() + "Any"](resource).granted;
  return granted;
};
const canDelete: canEditType = (rolePermissions: RolePermission | null, resource) => {
  let granted: boolean = false;
  if (!rolePermissions) return granted;
  const { permissions, roleId } = rolePermissions;
  //@ts-ignore
  const access = new AccessControl(permissions);
  let action: string = "DELETE";
  let crud: string[] = ["CREATE", "READ", "UPDATE", "DELETE"];
  if (crud.indexOf(action) > -1) granted = access.can(roleId)[action.toLowerCase() + "Any"](resource).granted;
  return granted;
};

export default class AuthStore {
  constructor(mapStore: MapStore, uiStore: UiStore) {
    this.mapStore = mapStore;
    this.uiStore = uiStore;
    makeObservable(this, {
      isLoggedIn: observable,
      user: observable,
      availableProfiles: observable,
      rolePermissions: observable,
      token: observable,
      authType: observable,
      canEdit: observable,
      doLogin: action.bound,
      startSSO: action.bound,
      doLoginSso: action.bound,
      loginResponseHandling: action.bound,
      validateToken: action.bound,
      doLogout: action.bound,
      forceLogout: action.bound,
      displayName: computed,
      canEditBaselayer: computed,
      doSelectProfile: action.bound,
      roleId: computed,
      profile: computed,
      authStrategies: computed,
    });

    makePersistable(this, {
      name: "AuthStore",
      properties: ["user", "profile", "isLoggedIn", "availableProfiles", "rolePermissions", "token", "authType", "canEdit"],
      storage: window.localStorage,
    }).then(
      action((persistStore) => {
        // console.log(persistStore.isHydrated);
        this.mapStore = mapStore;
        this.uiStore = uiStore;
        if (this.token) {
          setDefaultHeader(this.token);
          this.validateToken();
        }
      }),
    );
  }

  mapStore: MapStore;
  uiStore: UiStore;

  isLoggedIn: boolean = false;

  user: UserType = { firstName: "", lastName: "" };

  availableProfiles: any[] = [];

  rolePermissions: RolePermission | null;

  token: string | null = null;

  authType: AuthType = AuthType.USER_PASS;

  canEdit: boolean = false;

  get canEditBaselayer() {
    return canEdit(this.rolePermissions, "baselayer");
  }
  get canCreateBaselayer() {
    return canCreate(this.rolePermissions, "baselayer");
  }
  get canDeleteBaselayer() {
    return canDelete(this.rolePermissions, "baselayer");
  }
  get isHydrated() {
    return isHydrated(this);
  }

  doLogin = async (email: string, password: string): Promise<{ success: boolean; error?: string }> => {
    const data: LoginData | boolean = await login(email, password);
    this.authType = AuthType.USER_PASS;
    return this.loginResponseHandling(data);
  };

  startSSO = async (type: AuthType) => {
    const returnTo = `${window.location.origin}${ROUTE_SSO_SESSION_CALLBACK.replace(":authType", type)}`;
    switch (type) {
      case AuthType.KEYCLOAK:
        window.location.href = `${API_URL}oidc/${AuthType.KEYCLOAK}/login?returnTo=${returnTo}`;
        break;
      case AuthType.POLICE:
        window.location.href = `${API_URL}oidc/${AuthType.POLICE}/login?returnTo=${returnTo}`;
        break;
      case AuthType.ACMIDM:
        window.location.href = `${API_URL}oauth2/${AuthType.ACMIDM}/login?returnTo=${returnTo}`;
        break;
      default:
        console.warn(`Login strategy '${type}' not implemented`);
    }
  };

  doLoginSso = async ({ authType, sessionId }: { sessionId: string; authType: AuthType }) => {
    const data: LoginData | boolean = await loginSSO({ authType, sessionId });
    this.authType = authType;
    return this.loginResponseHandling(data);
  };

  loginResponseHandling = (data) => {
    let success = false;
    let error = "Wrong user/password";
    if (typeof data !== "boolean") {
      console.log("set user");
      this.user = data.user;
      console.log("set token");
      this.token = data.token;
      console.log("set profiles");
      this.availableProfiles = data.roles;
      this.rolePermissions = data.rolePermissions && data.rolePermissions[0];
      const latlng: LatLngType = {
        lat: data.municipality.lat,
        lng: data.municipality.lng,
      };

      console.log(data.user.language);
      this.uiStore.setLocale(data.user.language?.substring?.(0, 2) || DEFAULT_LOCALE);

      this.mapStore.setLatLng(latlng);
      //this.mapStore.setMunicipality(data.municipality.name); => [TODO] needs a new home
      this.isLoggedIn = true;
      if (this.rolePermissions) {
        this.canEdit = canEdit(this.rolePermissions, "geo-core");
        history.push(ROUTE_BASE);
      } else {
        history.push(ROUTE_SELECT_PROFILE);
      }
    } else {
      throw error;
    }
    return { success, error };
  };

  validateToken = async () => {
    try {
      const data: VerifyToken = await validatetoken(this.token);
      if (!data.verified) {
        this.forceLogout();
      }
      return true;
    } catch (error) {
      this.forceLogout();
      return false;
    }
  };

  /**
   * returns logged in status
   */
  doLogout = async (): Promise<boolean> => {
    await logout();
    this.forceLogout();
    return true;
  };

  forceLogout = (returnToUrl?: string) => {
    this.availableProfiles = [];
    this.rolePermissions = null;
    this.token = null;
    this.isLoggedIn = false;
    const returnTo = returnToUrl || `${window.location.origin}${ROUTE_LOGIN}`;
    switch (this.authType) {
      case AuthType.KEYCLOAK:
        window.location.href = `${API_URL}oidc/${this.authType}/logout?returnTo=${returnTo}`;
        break;
      case AuthType.ACMIDM:
        window.location.href = `${API_URL}oauth2/${this.authType}/logout?returnTo=${returnTo}`;
        break;
    }
  };

  get displayName() {
    let displayName = "";
    if (this.user && this.user.lastName) {
      displayName = `${this.user.lastName.toUpperCase()} ${firstLetterUpperCase(this.user.firstName)}`;
    }
    return displayName;
  }

  doSelectProfile = async (profileId) => {
    const { rolePermissions, token } = await setProfile(profileId);
    this.rolePermissions = rolePermissions;
    this.token = token;
    this.canEdit = canEdit(rolePermissions, "geo-core");
    linkTokenToAxios(token);
    setDefaultHeader(token);
  };

  get roleId() {
    return this.token ? jwt_decode(this.token).roleId : null;
  }

  get profile() {
    let profileName: string = "";
    if (this.availableProfiles && this.rolePermissions && this.availableProfiles[0] && this.rolePermissions.roleId) {
      profileName = this.rolePermissions.roleId;
    }
    return profileName;
  }

  get authStrategies() {
    return AUTH_STRATEGIES_CONFIG.filter((strat) => AUTH_STRATEGIES.includes(strat.type));
  }
}
