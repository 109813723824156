import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import OpenInNewTabIcon from "@material-ui/icons/Launch";
import CachedIcon from "@material-ui/icons/Cached";
import SearchIcon from "@material-ui/icons/Search";
import { withStyles } from "@material-ui/styles";
import {
  BaseLayerListObjectType,
  ConvertionTaskStatus,
  DefaultVisibilityCategory,
  DefaultVisibilityType,
  EditType,
  LayerGroup,
  LayerGroupStatus,
  LayerListObjectType,
  LayerType,
  PublicationEnrichment,
  SearchProperty,
} from "@orbit/geo-core-shared";
import clsx from "clsx";
import { getLayerGroupStatusColor, getLayerGroupStatusLabel } from "../../../constants";
import { StoresContext } from "index";
import { observer } from "mobx-react-lite";
import React, { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useClipboard } from "use-clipboard-copy";
import BaseMap from "views/Map/BaseMap";
import { DataLayerType } from "../../../stores/DataLayerStore";
import CustomDialogView from "../../../views/CustomDialog/CustomDialogView";
import { BootstrapInput } from "../datalayer/Datalayers";
import { SortBaseLayers, SortLayers } from "./layers/SortLayers";
import usePublicationDetailStyles from "./PublicationDetailStyles";
import { ConfigurationTable } from "./search/ConfigurationTable";
import { ROUTE_DATAGROUPS } from "routes/RouteList";
import { toJS } from "mobx";
import { AdornedButton } from "../datalayer/DatalayerDetail";
import { getCurrentBearer } from "services/auth";
import { ENRICHMENT_TYPES } from "~/types/types";
import { InjectedIntl, InjectedIntlProps, injectIntl } from "react-intl";
import messages from "./messages";
import { getItemMetaLabel } from "utils/string";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  variant: "menu",
  getContentAnchorEl: null,
};

const { VIEWER_URL = "" } = window.env;

type DrawToolsType = {
  select: boolean;
  point: boolean;
  linestring: boolean;
  polygon: boolean;
  edit: boolean;
  import: boolean;
  export: boolean;
  delete: boolean;
};

const PublicationDetail: FunctionComponent<{
  classes: any;
  droppable: boolean;
  intl: InjectedIntl;
}> = observer(({ classes, intl: { formatMessage }, intl }) => {
  const {
    uiStore: { locale },
    publicationStore: {
      publications,
      activePublication,
      activeSearchFields,
      activeSearchFieldIndex,
      activeSearchField,
      fetchActivePublication,
      clearActivePublication,
      updatePublication,
      wfsLayers,
      wmsLayers,
      sortedLayers,
      sortedBaseLayers,
      setSearchPropertyItemPrimary,
    },
    publicationStore,
    analyseStore: { analyseConfigs, loadAnalyseConfigs },
    enrichmentStore: { enrichments, loadEnrichments },
    dataGroupStore: { layergroups, loadLayergroupData },
    dataLayerStore: { layers: imageLayers, loadData },
    baseLayerStore: { baseLayers, loadBaseLayers },
    transferLayerStore: { transferLayers, loadTransferLayers },
  } = useContext(StoresContext);
  const { id } = useParams<{ id: string }>();
  const clipboard = useClipboard();
  const { push } = useHistory();

  const [editType, setEditType] = useState<EditType | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [layerGroup, setLayerGroup] = useState<string>("empty");
  const [transferLayer, setTransferLayer] = useState<string[]>(["empty"]);
  const [selectBaseLayers, setSelectBaseLayers] = useState<BaseLayerListObjectType[]>([]);
  const [selectDefaultZoom, setSelectDefaultZoom] = useState<LayerType[]>([]);
  const [selectDefaultVisibility, setSelectDefaultVisibility] = useState<DefaultVisibilityType>({
    [DefaultVisibilityCategory.DATALAYERS]: [],
    [DefaultVisibilityCategory.TRANSFERLAYERS]: [],
    [DefaultVisibilityCategory.IMAGES]: [],
  });
  const [selectedEnrichments, setSelectedEnrichments] = useState<string[]>([]);
  const [selectAnalyseConfig, setSelectAnalyseConfig] = useState<string>("empty");
  const [imageLayer, setImageLayer] = useState<LayerListObjectType[]>([]);
  const [naam, setNaam] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [transferLayerFilter, setTransferLayerFilter] = useState("both");
  const [selectedType, setSelectedType] = useState("all");
  const [useDrawTools, setUseDrawTools] = useState<boolean>(true);
  const [drawTools, setDrawTools] = useState<DrawToolsType>({
    select: true,
    point: true,
    linestring: true,
    polygon: true,
    edit: true,
    import: true,
    export: true,
    delete: true,
  });

  useEffect(() => {
    if (!id) {
      return;
    }
    fetchActivePublication(id);
    return () => {
      clearActivePublication();
    };
  }, [publications, id]);

  useEffect(() => {
    if (transferLayers?.length > 0) {
      const layers = activePublication?.transferLayer?.map?.((layer) => layer.id) || [];
      setTransferLayer(layers);
    }
  }, [transferLayers]);

  const openDialog = (type: EditType) => {
    setEditType(type);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);

    // reset baselayer when cancel
    setSelectBaseLayers(baseLayers?.filter((step) => activePublication?.baseLayers?.find((selected) => step.id === selected.id)) || []);
    setSelectedEnrichments(
      activePublication?.publicationEnrichments
        ?.map((enrichment) => enrichment.id)
        .filter((enrichmentId) => activePublication?.publicationEnrichments?.find((selected) => enrichmentId === selected.id)) || [],
    );
  };

  const handleTransferLayerFilterChange = (event) => {
    setTransferLayerFilter(event.target.value);
  };

  const handleLayerGroupChange = (event: React.ChangeEvent<{ value: string }>) => {
    setLayerGroup(event.target.value as string);
  };

  const handleTransferLayerChange = (value) => {
    transferLayer.find((element) => element === value) ? setTransferLayer(transferLayer.filter((layer) => layer !== value)) : setTransferLayer([...transferLayer, value]);
  };

  const handleBaseLayerChange = (event: React.ChangeEvent<{ value: BaseLayerListObjectType[] }>) => {
    setSelectBaseLayers(event.target.value);
  };

  const handleDefaultZoomChange = (event: React.ChangeEvent<{ value: LayerType[] }>) => {
    setSelectDefaultZoom(event.target.value);
  };

  const handleEnrichmentsChange = (event: React.ChangeEvent<{ value: string[] }>) => {
    setSelectedEnrichments(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleAnalyseConfigChange = (event: React.ChangeEvent<{ value: string }>) => {
    setSelectAnalyseConfig(event.target.value);
  };

  const handleImageLayerChange = (event: React.ChangeEvent<{ value: LayerListObjectType[] }>) => {
    console.log("handleImageLayerChange", event.target.value);
    setImageLayer(event.target.value);
  };

  const handleDrawToolsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDrawTools({
      ...drawTools,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    loadBaseLayers();
    loadAnalyseConfigs();
    loadEnrichments();
    loadLayergroupData();
    loadData(DataLayerType.BITMAP);
    loadTransferLayers();
  }, []);

  useEffect(() => {
    if (activePublication) {
      if (activePublication?.name) {
        setNaam(activePublication?.name);
      }
      if (activePublication?.layerGroup) {
        setLayerGroup(activePublication?.layerGroup.id);
      }
      if (activePublication.images) {
        /**
         * fix for reference in select
         * needs to be same ref
         */
        setImageLayer(imageLayers.filter((step) => activePublication?.images?.find((selected) => step.id === selected.id)));
      }
      if (activePublication.baseLayers) {
        /**
         * fix for reference in select
         * needs to be same ref
         */
        setSelectBaseLayers(baseLayers.filter((step) => activePublication?.baseLayers?.find((selected) => step.id === selected.id)));
      }
      if (activePublication.publicationEnrichments) {
        /**
         * fix for reference in select
         * needs to be same ref
         */
        setSelectedEnrichments(
          activePublication.publicationEnrichments
            .map((enrichment) => enrichment.id)
            .filter((enrichmentId) => activePublication?.publicationEnrichments?.find((selected) => enrichmentId === selected.id)),
        );
      }
      if (activePublication?.transferLayer?.length) {
        setTransferLayer(activePublication?.transferLayer.map((layer) => layer.id));
      }
      if (activePublication?.analyseConfig) {
        setSelectAnalyseConfig(activePublication?.analyseConfig?.id);
      }
      if (activePublication.drawTools) {
        setDrawTools(activePublication.drawTools);
      }
      const newSelectDefaultVisibility = { ...selectDefaultVisibility };
      if (!!activePublication?.layerGroup?.layers?.length) {
        newSelectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS] = activePublication?.style?.layers.map((layer) => {
          return { id: layer.id, label: layer["source-layer"], visible: layer.layout.visibility !== "none" };
        });
      } else {
        // need to set back to empty array else it will keep the previous value
        newSelectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS] = [];
      }
      if (!!activePublication?.sortedLayers?.filter((layer) => ["WFS", "WMS"].includes(layer.type)).length) {
        newSelectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS] = activePublication?.sortedLayers
          ?.filter((layer) => ["WFS", "WMS"].includes(layer.type))
          .map((layer) => {
            return { id: layer.id, label: layer.label, visible: layer.visible };
          });
      } else {
        // need to set back to empty array else it will keep the previous value
        newSelectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS] = [];
      }
      setSelectDefaultVisibility(newSelectDefaultVisibility);
    }
  }, [activePublication, imageLayers, baseLayers]);

  // using seperate useEffect to prevent above useEffect to trigger too many times
  useEffect(() => {
    if (activePublication && layergroups.length) {
      if (activePublication?.defaultZoom?.length && layerGroup !== "empty") {
        const layers = layergroups.filter((item) => item.id === layerGroup)[0].layers;
        setSelectDefaultZoom(activePublication?.defaultZoom.map((item) => layers.filter((layer) => layer.id === item)[0]));
      }
    }
  }, [activePublication, layergroups, layerGroup]);

  useEffect(() => {
    switch (editType) {
      case EditType.LAYERGROUP:
        setDialogTitle(formatMessage(messages.publicationsModifyDatagroup));
        break;
      case EditType.BASELAYER:
        setDialogTitle(formatMessage(messages.publicationsModifyBaselayers));
        break;
      case EditType.IMAGELAYER:
        setDialogTitle(formatMessage(messages.publicationsModifyImages));
        break;
      case EditType.NAME:
        setDialogTitle(formatMessage(messages.publicationsModifyTitlePublication));
        break;
      case EditType.Search:
        setDialogTitle(formatMessage(messages.publicationsModifySearch));
        break;
      case EditType.TRANSFERLAYER:
        setDialogTitle(formatMessage(messages.publicationsChooseWMSWFS));
        break;
      case EditType.ANALYSE:
        setDialogTitle(formatMessage(messages.publicationsChooseConfiguration));
        break;
      case EditType.ENRICHMENT:
        setDialogTitle(formatMessage(messages.publicationsChooseExtraEnrichments));
        break;
      // case EditType.DRAWTOOLS:
      //   setDialogTitle(formatMessage(messages.publicationsDrawToolsHeader));
      //   break;
      case EditType.DEFAULTZOOM:
        setDialogTitle(formatMessage(messages.publicationsChooseDefaultZoomTitle));
        break;
      case EditType.DEFAULTVISIBILITY:
        setDialogTitle(formatMessage(messages.publicationsDefaultVisibilityTitle));
        break;
      default:
        setDialogTitle(`${formatMessage(messages.publicationsTodoLinkType)} ${editType}`);
        break;
    }
  }, [editType]);

  const handleSave = (e) => {
    let data: string | string[] | { [key: string]: object | SearchProperty[] } | object;
    switch (editType) {
      case EditType.LAYERGROUP:
        data = layerGroup === "empty" ? "" : layerGroup;
        break;
      case EditType.Search:
        data = activePublication?.searchFields ?? {};
        break;
      case EditType.BASELAYER:
        data = selectBaseLayers.map((baseLayer) => baseLayer.id);
        break;
      case EditType.IMAGELAYER:
        data = imageLayer.map((baseLayer) => baseLayer.id);
        break;
      case EditType.NAME:
        data = naam;
        break;
      case EditType.TRANSFERLAYER:
        data = transferLayer;
        break;
      case EditType.ENRICHMENT:
        data = selectedEnrichments.map((enrichmentId) => enrichmentId);
        break;
      case EditType.ANALYSE:
        data = selectAnalyseConfig === "empty" ? "" : selectAnalyseConfig;
        break;
      // case EditType.DRAWTOOLS:
      //   data = drawTools;
      //   break;
      case EditType.DEFAULTZOOM:
        data = selectDefaultZoom.map((layer) => layer.id);
        break;
      case EditType.DEFAULTVISIBILITY:
        data = selectDefaultVisibility;
        break;
      default:
        data = "";
        console.log(`${formatMessage(messages.publicationsTodoLinkToSave)}${editType}`);
        break;
    }
    if (editType) {
      updatePublication(id, editType, data);
    }
    closeDialog();
  };

  const handleSelectDefaultVisibilityChange = ({ type, toggleId }: { type: DefaultVisibilityCategory; toggleId: string }) => {
    const newSelectDefaultVisibility = { ...selectDefaultVisibility };
    const index = newSelectDefaultVisibility[type].findIndex((item) => item.id === toggleId);
    newSelectDefaultVisibility[type][index].visible = !newSelectDefaultVisibility[type][index].visible;
    setSelectDefaultVisibility(newSelectDefaultVisibility);
  };

  const handleSelectDefaultVisibilityAllChange = ({ type, value }: { type: DefaultVisibilityCategory; value: boolean }) => {
    const newSelectDefaultVisibility = { ...selectDefaultVisibility };
    for (const visibilityItem of newSelectDefaultVisibility[type]) {
      visibilityItem.visible = value;
    }
    setSelectDefaultVisibility(newSelectDefaultVisibility);
  };

  const handleCopyConfig = () => {
    const indices = activeSearchFields.map((indice) => ({ index: indice.index, id: indice.name.replace(/ /, "_"), data: [], buffer: 0 }));
    clipboard.copy(JSON.stringify(indices));
  };

  const isEnrichment = useMemo(() => {
    return activePublication?.publicationEnrichments.find((item) => item.id === activeSearchFieldIndex);
  }, [activePublication, activeSearchFieldIndex]);

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          {/* <Button
            className={classes.urlButton}
            onClick={() => openDialog(EditType.NAME)}
            variant="outlined"
            size="small"
            startIcon={<EditIcon />}
          >
            Bewerken
          </Button> */}
          <Typography variant="h4" gutterBottom>
            {formatMessage(messages.publicationsDetailsPublication)} {activePublication?.name}
            <IconButton onClick={() => openDialog(EditType.NAME)}>
              <EditIcon />
            </IconButton>
          </Typography>
          <Typography variant="h6" gutterBottom>
            {getItemMetaLabel({ intl, item: activePublication })}
          </Typography>

          <div style={{ width: "100%", height: 500 }}>
            <BaseMap
              bounds={activePublication?.bbox}
              latLng={{
                lat: 50.98935,
                lng: 3.87118,
              }}
              dataFilter={publicationStore?.searchFilter}
              baseLayers={sortedBaseLayers}
              mapStyle={publicationStore?.getPublicationActiveStyle()}
              sortedLayers={sortedLayers}
              wmsLayers={wmsLayers}
              wfsLayers={wfsLayers}
            />
            {!!activeSearchFields.length && (
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    {formatMessage(messages.publicationsSearchConfiguration)}
                    <Tooltip title={formatMessage(messages.publicationsCopyConfigurationJson)}>
                      <IconButton aria-label={formatMessage(messages.publicationsCopyConfigurationJson)} onClick={handleCopyConfig}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <Select
                      fullWidth
                      value={activeSearchFieldIndex}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        publicationStore.activeSearchFieldIndex = event.target.value as string;
                      }}
                      input={<BootstrapInput />}
                    >
                      {activeSearchFields.map((item, index) => (
                        <MenuItem key={item?.index} value={item?.index}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <FormControl fullWidth className={classes.marginBottom}>
                      <InputLabel id="primary-label">{formatMessage(messages.publicationsPrimaryField)}</InputLabel>
                      <Select
                        labelId="primary-label"
                        fullWidth
                        value={activeSearchField.items.find((item) => item.primary)?.key || "empty"}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                          const value = event.target.value === "empty" ? "" : (event.target.value as string);
                          setSearchPropertyItemPrimary(value);
                        }}
                      >
                        <MenuItem aria-label="None" value="empty">
                          {formatMessage(messages.publicationsNoPrimary)}
                        </MenuItem>
                        {activeSearchField.items.map((item, index) => (
                          <MenuItem key={item.key} value={item.key}>
                            {item.key}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeSearchField.included}
                          onChange={(event) => {
                            event.stopPropagation();
                            activeSearchField.included = !activeSearchField.included;
                            publicationStore.saveSearchFields();
                          }}
                          name={formatMessage(messages.publicationsSearchable)}
                        />
                      }
                      label={formatMessage(isEnrichment ? messages.publicationsMakeEnrichmentSearchable : messages.publicationsMakeSearchable)}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeSearchField.fuzzy}
                          onChange={event => {
                            event.stopPropagation();
                            activeSearchField.fuzzy = !activeSearchField.fuzzy;
                            publicationStore.saveSearchFields();
                          }}
                          name={formatMessage(messages.publicationsFuzzy)}
                        />
                      }
                      label={formatMessage(messages.publicationsUseFuzzySearch)}
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} style={{ marginTop: 15, marginBottom: 20 }}>
                  <ConfigurationTable key={activeSearchFieldIndex} index={activeSearchFieldIndex} intl={intl} />
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            {formatMessage(messages.publicationsPublicationURLs)}
          </Typography>
          <Grid container direction="column" spacing={2} className={classes.urlContainer}>
            {activePublication && (
              <>
                {/* <Grid item>
                  <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                    <InputLabel htmlFor="viewer">{formatMessage(messages.publicationsViewer)}</InputLabel>
                    <Input
                      id="viewer"
                      type="text"
                      fullWidth
                      value={`${window.location.origin}/viewer/${activePublication.id}`}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={formatMessage(messages.publicationsCopyURL)}
                            onClick={() => window.open(`${window.location.origin}/viewer/${activePublication.id}`, "_blank")?.focus()}
                          >
                            <OpenInNewTabIcon />
                          </IconButton>
                          <IconButton
                            aria-label={formatMessage(messages.publicationsOpenURL)}
                            onClick={() => clipboard.copy(`${window.location.origin}/viewer/${activePublication.id}`)}
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid> */}
                <Grid item>
                  <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                    <InputLabel htmlFor="viewer-new">{formatMessage(messages.publicationsViewerNew)}</InputLabel>
                    <Input
                      id="viewer"
                      type="text"
                      fullWidth
                      value={`${VIEWER_URL}/${activePublication.id}`}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={formatMessage(messages.publicationsCopyURL)}
                            onClick={() =>
                              window
                                ?.open?.(
                                  `${VIEWER_URL}/login/token/${getCurrentBearer() || "invalid"}/publication/${activePublication.id}?rootUser=true&lang=${locale || ""}`,
                                  "_blank",
                                )
                                ?.focus()
                            }
                          >
                            <OpenInNewTabIcon />
                          </IconButton>
                          <IconButton aria-label={formatMessage(messages.publicationsOpenURL)} onClick={() => clipboard.copy(`${VIEWER_URL}/${activePublication.id}`)}>
                            <FileCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={clsx(classes.margin, classes.textField)} fullWidth>
                    <InputLabel htmlFor="key">{formatMessage(messages.publicationsKey)}</InputLabel>
                    <Input
                      id="key"
                      type="text"
                      fullWidth
                      value={activePublication.id}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label={formatMessage(messages.publicationsCopyKey)} onClick={() => clipboard.copy(activePublication.id)}>
                            <FileCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.LAYERGROUP)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsDatagroup)}
            </Typography>
            {activePublication?.layerGroup && (
              <>
                <Tooltip title={getLayerGroupStatusLabel(activePublication.layerGroup.status)}>
                  <a style={{ cursor: "pointer" }} onClick={() => push(`${ROUTE_DATAGROUPS}/${activePublication.layerGroup.id}`)}>
                    <span
                      style={{
                        marginLeft: 8,
                        marginRight: 8,
                        display: "inline-block",
                        borderRadius: "50%",
                        border: "5px solid",
                        borderColor: getLayerGroupStatusColor(activePublication.layerGroup.status),
                      }}
                    />
                    {activePublication?.layerGroup?.name}
                  </a>
                </Tooltip>
                <AdornedButton
                  className={classes.button}
                  fullWidth
                  variant="outlined"
                  type="submit"
                  color="primary"
                  startIcon={<CachedIcon />}
                  onClick={() => updatePublication(activePublication.id, EditType.STYLE, {})}
                >
                  {formatMessage(messages.publicationsSynchronizePublication)}
                </AdornedButton>
              </>
            )}
            {activePublication && !activePublication.layerGroup && <li>{formatMessage(messages.publicationsNoLinkedDatagroup)}</li>}
          </div>
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.IMAGELAYER)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsImages)}
            </Typography>
            {activePublication?.images?.map((image) => (
              <li key={image.id}>{image.displayName}</li>
            ))}
            {activePublication?.images?.length === 0 && <li>{formatMessage(messages.publicationsNoLinkedImages)}</li>}
          </div>
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.BASELAYER)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsBaselayers)}
            </Typography>
            {/* {activePublication?.baseLayers?.map(baseLayer => (
              <li key={baseLayer.id}>{baseLayer.title}</li>
            ))} */}
            {activePublication?.baseLayers && <SortBaseLayers />}
            {activePublication?.baseLayers?.length === 0 && <li>{formatMessage(messages.publicationsNoLinkedBaselayers)}</li>}
          </div>
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.TRANSFERLAYER)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsChooseWMSWFS)}
            </Typography>
            <ul>
              {activePublication?.transferLayer?.map?.(({ name, type }) => (
                <li>
                  [{type}]{name}
                </li>
              ))}
              {activePublication?.transferLayer?.length === 0 && <li>{formatMessage(messages.publicationsNoLinkedWMSWFS)}</li>}
            </ul>
          </div>
          <div className={classes.urlContainer}>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsDefineOrderLayers)}
            </Typography>
            <SortLayers layers={sortedLayers} />
          </div>
          {/* <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.DRAWTOOLS)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsChooseDrawTools)}
            </Typography>
            <List dense>
              {Object.entries(drawTools)
                .filter(([_, value]) => value === true)
                .map(([key, _]) => (
                  <ListItem>{key}</ListItem>
                ))}
            </List>
          </div> */}
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.DEFAULTVISIBILITY)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsDefaultVisibility)}
            </Typography>
          </div>
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.DEFAULTZOOM)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsChooseDefaultZoom)}
            </Typography>
            <ul>
              {activePublication?.defaultZoom?.map?.((item) => (
                <li>{activePublication?.layerGroup?.layers?.filter((layer) => layer.id === item)[0]?.displayName}</li>
              ))}
              {activePublication?.defaultZoom?.length === 0 && <li>{formatMessage(messages.publicationsNoDefaultZoom)}</li>}
            </ul>
          </div>
          {/* indices */}
          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.ENRICHMENT)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsESData)}
            </Typography>
            <ul>
              {!activePublication?.publicationEnrichments || activePublication?.publicationEnrichments?.length === 0 ? (
                <li>{formatMessage(messages.publicationsNoLinkedEnrichment)}</li>
              ) : (
                activePublication?.publicationEnrichments?.map(({ name, type }: PublicationEnrichment, index: number) => (
                  <li key={index}>
                    {name} ({type})
                  </li>
                ))
              )}
            </ul>
          </div>

          <div className={classes.urlContainer}>
            <Button className={classes.urlButton} onClick={() => openDialog(EditType.ANALYSE)} variant="outlined" size="small" startIcon={<EditIcon />}>
              {formatMessage(messages.publicationsModify)}
            </Button>
            <Typography variant="h5" gutterBottom>
              {formatMessage(messages.publicationsAnalyse)}
            </Typography>
            <ul>{activePublication?.analyseConfig ? <li>{activePublication?.analyseConfig.name}</li> : <li>{formatMessage(messages.publicationsNoLinkedAnalyse)}</li>}</ul>
          </div>
        </Grid>
      </Grid>
      <CustomDialogView
        open={dialogOpen}
        handleClose={() => {
          closeDialog();
        }}
        maxWidth={editType === EditType.Search ? "lg" : "sm"}
        dialogTitle={dialogTitle}
        dialogContent={
          <Fragment>
            {editType === EditType.NAME && (
              <TextField className={classes.marginBottom} required id="publicatiename" label="Publicatienaam" value={naam} onChange={(e) => setNaam(e.target.value)} fullWidth />
            )}
            {editType === EditType.LAYERGROUP && (
              <FormControl fullWidth className={classes.marginBottom}>
                <InputLabel id="datagroup-label">{formatMessage(messages.publicationsChooseDatagroup)}</InputLabel>
                <Select labelId="datagroup-label" id="datagroup" value={layerGroup} fullWidth onChange={handleLayerGroupChange}>
                  <MenuItem aria-label="None" value="empty">
                    {formatMessage(messages.publicationsNoDatagroup)}
                  </MenuItem>
                  {layergroups.map((layergroup) => (
                    <MenuItem disabled={layergroup.status !== LayerGroupStatus.FINISHED} value={layergroup.id}>
                      {layergroup.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {editType === EditType.IMAGELAYER && (
              <FormControl fullWidth className={classes.marginBottom}>
                {imageLayers.length ? (
                  <>
                    <InputLabel id="afbeeldingen-label">{formatMessage(messages.publicationsChooseImages)}</InputLabel>
                    <Select
                      labelId="afbeeldingen-label"
                      id="afbeeldingen"
                      multiple
                      value={imageLayer}
                      onChange={handleImageLayerChange}
                      input={<BootstrapInput />}
                      MenuProps={MenuProps}
                      renderValue={(items: LayerListObjectType[]) =>
                        items.map((item) => (
                          <Chip key={item.id} label={item.displayName} style={{ marginRight: 8, marginBottom: 8, backgroundColor: "#386FA4", color: "#fff", borderRadius: 4 }} />
                        ))
                      }
                    >
                      {imageLayers.map((layer) => (
                        <MenuItem key={layer.id} value={layer} disabled={layer.status !== ConvertionTaskStatus.FINISHED}>
                          <Checkbox disabled={layer.status !== ConvertionTaskStatus.FINISHED} checked={imageLayer.filter((item) => item.id === layer.id).length > 0} />
                          {layer.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <Typography>{formatMessage(messages.publicationsNoCurrentImages)}</Typography>
                )}
              </FormControl>
            )}
            {editType === EditType.BASELAYER && (
              <FormControl fullWidth className={classes.marginBottom}>
                {baseLayers.length ? (
                  <>
                    <InputLabel id="basiskaart-label">{formatMessage(messages.publicationsChooseBaselayers)}</InputLabel>
                    <Select
                      multiple
                      labelId="basiskaart-label"
                      id="basiskaart"
                      MenuProps={MenuProps}
                      value={selectBaseLayers}
                      onChange={handleBaseLayerChange}
                      input={<BootstrapInput />}
                      renderValue={(items: BaseLayerListObjectType[]) =>
                        items.map((item) => (
                          <Chip key={item.id} label={item.title} style={{ marginRight: 8, marginBottom: 8, backgroundColor: "#386FA4", color: "#fff", borderRadius: 4 }} />
                        ))
                      }
                    >
                      {baseLayers.map((layer) => (
                        <MenuItem key={layer.id} value={layer}>
                          <Checkbox checked={selectBaseLayers.filter((item) => item.id === layer.id).length > 0} />
                          {layer.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <Typography>{formatMessage(messages.publicationsNoCurrentBaselayers)}</Typography>
                )}
              </FormControl>
            )}
            {editType === EditType.TRANSFERLAYER && (
              <Grid container>
                <Grid item xs={12}>
                  <FormControl className={classes.searchField} fullWidth style={{ marginBottom: 15 }}>
                    <InputLabel htmlFor="outlined-adornment-password">{formatMessage(messages.publicationsSearchName)}</InputLabel>
                    <Input
                      id="outlined-adornment-password"
                      type="text"
                      value={searchValue}
                      fullWidth
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label={formatMessage(messages.publicationsSearch)} onClick={() => {}} onMouseDown={() => {}} edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      //labelWidth={128}
                    />
                  </FormControl>
                  <FormControl className={classes.searchField} fullWidth style={{ marginBottom: 15 }}>
                    <FormLabel component="legend">{formatMessage(messages.publicationsSearchType)}</FormLabel>
                    <RadioGroup name="transferLayerType" value={transferLayerFilter} onChange={handleTransferLayerFilterChange}>
                      <Grid container>
                        <Grid item xs={4}>
                          <FormControlLabel value="both" control={<Radio size="small" />} label={formatMessage(messages.publicationsWMSWFS)} />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="wms" control={<Radio size="small" />} label={formatMessage(messages.publicationsOnlyWMS)} />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel value="wfs" control={<Radio size="small" />} label={formatMessage(messages.publicationsOnlyWFS)} />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" />
                        <TableCell>{formatMessage(messages.publicationsType)}</TableCell>
                        <TableCell>{formatMessage(messages.publicationsName)}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transferLayers
                        .filter((layer) => {
                          if (transferLayerFilter === "both") return true;
                          return layer.type === transferLayerFilter;
                        })
                        .filter((layer) => (searchValue.length ? layer.name.toLowerCase().includes(searchValue.toLowerCase()) : true))
                        .map((layer) => (
                          <TableRow key={layer.id}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={transferLayer.includes(layer.id)} onClick={() => handleTransferLayerChange(layer.id)} />
                            </TableCell>
                            <TableCell>{layer.type}</TableCell>
                            <TableCell>{layer.name}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            )}
            {editType === EditType.ENRICHMENT && (
              <>
                {enrichments?.length > 0 && (
                  <>
                    <Typography>{formatMessage(messages.publicationsFilterEnrichmentType)}</Typography>
                    <RadioGroup
                      aria-label="enrichment-type"
                      name="enrichment-type"
                      value={selectedType}
                      onChange={handleTypeChange}
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}
                    >
                      <FormControlLabel value="all" control={<Radio />} label={formatMessage(messages.publicationsAll)} />
                      <FormControlLabel value="SEARCH" control={<Radio />} label={formatMessage(messages.publicationsSearchEnglish)} />
                      <FormControlLabel value="DEFAULT" control={<Radio />} label={formatMessage(messages.publicationsDefault)} />
                    </RadioGroup>
                    <Divider style={{ margin: "16px 0" }} />
                  </>
                )}
                {enrichments?.length ? (
                  <FormControl fullWidth className={classes.marginBottom}>
                    <InputLabel id="enrichment-label">{formatMessage(messages.publicationsChooseEnrichments)}</InputLabel>
                    <Select
                      multiple
                      labelId="enrichment-label"
                      id="enrichment"
                      MenuProps={MenuProps}
                      value={selectedEnrichments}
                      onChange={handleEnrichmentsChange}
                      input={<BootstrapInput />}
                      renderValue={(items: string[]) => (
                        <Box style={{ display: "flex", flexWrap: "wrap" }}>
                          {items.map((item, index) => {
                            const enrichment = enrichments?.find((enrichment) => enrichment.id === item);
                            if (enrichment) {
                              return (
                                <Chip
                                  key={enrichment.id}
                                  label={`${enrichment.name} (${enrichment.type})`}
                                  style={{ marginRight: 8, marginBottom: 8, backgroundColor: "#386FA4", color: "#fff", borderRadius: 4 }}
                                />
                              );
                            }
                            return null;
                          })}
                        </Box>
                      )}
                    >
                      {enrichments
                        ?.filter((item) => item.type === selectedType || selectedType === "all")
                        .map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={selectedEnrichments.includes(item.id)} />
                            {item.name} ({item.type})
                          </MenuItem>
                        ))}
                      {enrichments?.filter((item) => item.type === selectedType || selectedType === "all").length === 0 && (
                        <MenuItem disabled>{formatMessage(messages.publicationsNoEnrichments)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography>{formatMessage(messages.publicationsNoCurrentEnrichments)}</Typography>
                )}
              </>
            )}
            {editType === EditType.ANALYSE && (
              <FormControl fullWidth className={classes.marginBottom}>
                {analyseConfigs.length ? (
                  <>
                    <InputLabel id="analyse-label">{formatMessage(messages.publicationsNameAnalyseConfig)}</InputLabel>
                    <Select labelId="analyse-label" id="analyse" value={selectAnalyseConfig} fullWidth onChange={handleAnalyseConfigChange}>
                      <MenuItem aria-label={formatMessage(messages.publicationsNone)} value="empty">
                        {formatMessage(messages.publicationsNoAnalyse)}
                      </MenuItem>
                      {analyseConfigs.map((analyseConfig) => (
                        <MenuItem key={analyseConfig?.id} value={analyseConfig.id}>
                          {analyseConfig.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : (
                  <Typography>{formatMessage(messages.publicationsNoCurrentAnalyse)}</Typography>
                )}
              </FormControl>
            )}
            {/* {editType === EditType.DRAWTOOLS && (
              <>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={drawTools.select} onChange={handleDrawToolsChange} name="select" />} label="Select" />
                    <FormControlLabel control={<Checkbox checked={drawTools.point} onChange={handleDrawToolsChange} name="point" />} label="Point" />
                    <FormControlLabel control={<Checkbox checked={drawTools.linestring} onChange={handleDrawToolsChange} name="linestring" />} label="LineString" />
                    <FormControlLabel control={<Checkbox checked={drawTools.polygon} onChange={handleDrawToolsChange} name="polygon" />} label="Polygon/Rectangle/Circle" />
                    <FormControlLabel control={<Checkbox checked={drawTools.edit} onChange={handleDrawToolsChange} name="edit" />} label="Edit" />
                    <FormControlLabel control={<Checkbox checked={drawTools.export} onChange={handleDrawToolsChange} name="export" />} label="Export" />
                    <FormControlLabel control={<Checkbox checked={drawTools.import} onChange={handleDrawToolsChange} name="import" />} label="Import" />
                    <FormControlLabel control={<Checkbox checked={drawTools.delete} onChange={handleDrawToolsChange} name="delete" />} label="Delete" />
                  </FormGroup>
                </FormControl>
              </>
            )} */}
            {editType === EditType.DEFAULTZOOM && (
              <FormControl fullWidth className={classes.marginBottom}>
                {layerGroup !== "empty" ? (
                  <>
                    <Typography>{formatMessage(messages.publicationsChooseDefaultZoomLabel)}</Typography>
                    <Select
                      multiple
                      id="defaultzoom"
                      MenuProps={MenuProps}
                      value={selectDefaultZoom}
                      onChange={handleDefaultZoomChange}
                      input={<BootstrapInput />}
                      renderValue={(items: LayerType[]) =>
                        items.map((item) => (
                          <Chip key={item.id} label={item.displayName} style={{ marginRight: 8, marginBottom: 8, backgroundColor: "#386FA4", color: "#fff", borderRadius: 4 }} />
                        ))
                      }
                    >
                      {layergroups
                        .filter((item) => item.id === layerGroup)[0]
                        .layers.map((layer) => (
                          <MenuItem key={layer.id} value={layer}>
                            <Checkbox checked={selectDefaultZoom.filter((item) => item.id === layer.id).length > 0} />
                            {layer.displayName}
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : (
                  <Typography>{formatMessage(messages.publicationsNoLayerGroupSelected)}</Typography>
                )}
              </FormControl>
            )}
            {editType === EditType.DEFAULTVISIBILITY && (
              <>
                <Typography variant="caption" gutterBottom>
                  {formatMessage(messages.publicationsDefaultVisibilityDescription)}
                </Typography>
                <Typography variant="subtitle2">
                  {formatMessage(messages.publicationsDefaultVisibilityDatalayers)}
                  {!!selectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS].length && (
                    <IconButton
                      style={{ marginLeft: 1 }}
                      size="small"
                      onClick={() =>
                        handleSelectDefaultVisibilityAllChange({
                          type: DefaultVisibilityCategory.DATALAYERS,
                          value: !(selectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS].filter(({ visible }) => visible).length > 0),
                        })
                      }
                    >
                      {selectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS].filter(({ visible }) => visible).length > 0 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  )}
                </Typography>
                {!!selectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS].length ? (
                  <List>
                    {selectDefaultVisibility[DefaultVisibilityCategory.DATALAYERS].map(({ id, label, visible }) => (
                      <ListItem
                        key={id}
                        role={undefined}
                        dense
                        button
                        onClick={() => handleSelectDefaultVisibilityChange({ type: DefaultVisibilityCategory.DATALAYERS, toggleId: id })}
                      >
                        <ListItemIcon>{visible ? <VisibilityIcon /> : <VisibilityOffIcon />}</ListItemIcon>
                        <ListItemText id={id} primary={label} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="caption">{formatMessage(messages.publicationsDefaultVisibilityDatalayersNotFound)}</Typography>
                )}
                <Typography variant="subtitle2">
                  {formatMessage(messages.publicationsDefaultVisibilityTransferlayers)}
                  {!!selectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS].length && (
                    <IconButton
                      style={{ marginLeft: 1 }}
                      size="small"
                      onClick={() =>
                        handleSelectDefaultVisibilityAllChange({
                          type: DefaultVisibilityCategory.TRANSFERLAYERS,
                          value: !(selectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS].filter(({ visible }) => visible).length > 0),
                        })
                      }
                    >
                      {selectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS].filter(({ visible }) => visible).length > 0 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  )}
                </Typography>
                {!!selectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS].length ? (
                  <List>
                    {selectDefaultVisibility[DefaultVisibilityCategory.TRANSFERLAYERS].map(({ id, label, visible }) => (
                      <ListItem
                        key={id}
                        role={undefined}
                        dense
                        button
                        onClick={() => handleSelectDefaultVisibilityChange({ type: DefaultVisibilityCategory.TRANSFERLAYERS, toggleId: id })}
                      >
                        <ListItemIcon>{visible ? <VisibilityIcon /> : <VisibilityOffIcon />}</ListItemIcon>
                        <ListItemText id={id} primary={label} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="caption">{formatMessage(messages.publicationsDefaultVisibilityTransferlayersNotFound)}</Typography>
                )}
                <Typography variant="subtitle2">
                  {formatMessage(messages.publicationsDefaultVisibilityImages)}
                  {!!selectDefaultVisibility[DefaultVisibilityCategory.IMAGES].length && (
                    <IconButton
                      style={{ marginLeft: 1 }}
                      size="small"
                      onClick={() =>
                        handleSelectDefaultVisibilityAllChange({
                          type: DefaultVisibilityCategory.IMAGES,
                          value: !(selectDefaultVisibility[DefaultVisibilityCategory.IMAGES].filter(({ visible }) => visible).length > 0),
                        })
                      }
                    >
                      {selectDefaultVisibility[DefaultVisibilityCategory.IMAGES].filter(({ visible }) => visible).length > 0 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  )}
                </Typography>
                {!!selectDefaultVisibility[DefaultVisibilityCategory.IMAGES].length ? (
                  <List>
                    <ListItem role={undefined} dense button onClick={() => {}}>
                      <ListItemIcon>{true ? <VisibilityIcon /> : <VisibilityOffIcon />}</ListItemIcon>
                      <ListItemText id={"test"} primary={"test"} />
                    </ListItem>
                  </List>
                ) : (
                  <Typography variant="caption">{formatMessage(messages.publicationsDefaultVisibilityImagesNotFound)}</Typography>
                )}
              </>
            )}
          </Fragment>
        }
        dialogActions={
          <Fragment>
            <Button
              id="submit"
              onClick={() => {
                closeDialog();
              }}
            >
              {formatMessage(messages.publicationsCancel)}
            </Button>
            <Button id="submit" color="primary" variant="contained" disabled={naam.length === 0} onClick={handleSave} disableElevation>
              {formatMessage(messages.publicationsSave)}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
});

export default withStyles(usePublicationDetailStyles)(injectIntl(PublicationDetail));
