import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router";
import messages from "./messages";
import { InjectedIntl, injectIntl } from "react-intl";
import { observer } from "mobx-react-lite";
import { getItemMetaLabel } from "../../utils/string";
import CustomDialogView from "views/CustomDialog/CustomDialogView";

export enum SortEnum {
  ALPHABETICAL = "alphabetical",
  DATE_CREATED = "dateCreated",
  DATE_MODIFIED = "dateModified",
}

export type SortType = {
  type: SortEnum;
  sortDirection: "ASC" | "DESC";
};

export interface ListViewItem {
  id: string;
  dateModified?: Date;
  dateCreated: Date;
  avatarUrl?: string;
  name: string;
  status: string;
  statusColor: string;
  user?: { firstName: string; lastName: string };
  fileType?: string;
}
export interface ListViewProps {
  title: string;
  singleItemName: string;
  searchprompt: string;
  items: ListViewItem[];
  canDelete: boolean;
  hasStatus: boolean;
  hasDateModified: boolean;
  path: string;
  handleDelete?: (activeLayerIdMenu: string) => void;
  intl: InjectedIntl;
  leadingControls?: JSX.Element;
}

const ListView: FunctionComponent<ListViewProps> = observer(
  ({ title, searchprompt, singleItemName, items, canDelete, hasStatus, hasDateModified, path, handleDelete, leadingControls, intl, intl: { formatMessage } }) => {
    let { push } = useHistory();
    const [searchValue, setSearchValue] = useState("");
    const [filteredItems, setFiltererdItems] = useState(items);
    const [activeLayerIdMenu, setActiveLayerIdMenu] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const openDialog = () => {
      setDialogOpen(true);
    };
    const closeDialog = () => {
      setDialogOpen(false);
    };

    const [sort, setSort] = useState<SortType>({
      type: SortEnum.DATE_CREATED,
      sortDirection: "ASC",
    });

    const handleClose = () => {
      setAnchorEl(null);
    };

    function sortName({ sortDirection }: SortType) {
      const sortedItems = items.slice().sort((a, b) => a.name.localeCompare(b.name));

      if (sortDirection === "DESC") {
        sortedItems.reverse();
      }

      return sortedItems;
    }

    function sortDate({ type, sortDirection }: SortType) {
      const sortedItems = items.slice().sort((date1, date2) => new Date(date2[type]).getTime() - new Date(date1[type]).getTime());

      if (sortDirection === "DESC") {
        sortedItems.reverse();
      }

      return sortedItems;
    }

    function getSort(newType: string, oldSortType: { type: string; sortDirection: string }) {
      if (newType !== oldSortType.type) {
        return "ASC";
      }

      return oldSortType.sortDirection === "ASC" ? "DESC" : "ASC";
    }

    useEffect(() => {
      let newLayers = items;
      if (sort.type === SortEnum.ALPHABETICAL) {
        newLayers = sortName(sort);
      }
      if (sort.type === SortEnum.DATE_CREATED) {
        newLayers = sortDate(sort);
      }
      if (sort.type === SortEnum.DATE_MODIFIED) {
        newLayers = sortDate(sort);
      }
      setFiltererdItems(searchValue !== "" ? newLayers.filter((layer) => layer.name.toLowerCase().includes(searchValue.toLowerCase())) : newLayers);
    }, [items, sort, searchValue]);

    function getSortDirectionIcons({ type, sortDirection }: SortType) {
      return (
        <>
          <ArrowUpwardIcon style={{ marginBottom: 1, fontSize: "small", opacity: sortDirection === "ASC" && sort.type === type ? 1 : 0.2 }} />
          <ArrowDownwardIcon style={{ marginTop: 1, fontSize: "small", opacity: sortDirection === "DESC" && sort.type === type ? 1 : 0.2 }} />
        </>
      );
    }

    const handleLayer = async (action: string) => {
      handleClose();
      //if (action === "watch") push(ROUTE_DATAGROUPS + "/map/" + activeLayerIdMenu);
      if (action === "details") {
        return push(`${path}/${activeLayerIdMenu}`);
      }
      if (action === "delete" && canDelete) {
        openDialog();
      }
      console.log("Nog op te vangen:", action);
    };

    function getStatusColor(statusColor: string | undefined) {
      return (
        <span
          style={{
            marginLeft: 8,
            display: "inline-block",
            borderRadius: "50%",
            border: "6px solid",
            borderColor: statusColor,
          }}
        />
      );
    }

    return (
      <>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handleLayer("details")}>{formatMessage(messages.listviewDetails)}</MenuItem>
          {canDelete && <MenuItem onClick={() => handleLayer("delete")}>{formatMessage(messages.listviewDelete)}</MenuItem>}
        </Menu>
        <Box display="flex" flexDirection="row" alignItems="center">
          <FormControl
            // className={classes.searchField}
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {searchprompt} {formatMessage(messages.listviewSearch)}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={searchValue}
              fullWidth
              onChange={(e) => {
                setSearchValue(e.target.value.trimLeft());
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label={formatMessage(messages.listviewSearchEnglish)} onClick={() => {}} onMouseDown={() => {}} edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={128}
            />
          </FormControl>
          {leadingControls}
        </Box>
        {filteredItems.length === 0 && (
          <div>
            {formatMessage(messages.listviewNone)} {title.toLocaleLowerCase()} {formatMessage(messages.listviewFound)}
            {searchValue !== "" && (
              <span>
                {" "}
                {formatMessage(messages.listviewKeyword)} "{searchValue}".
              </span>
            )}
            {searchValue === "" && (
              <span>
                {" "}
                {formatMessage(messages.listviewPleaseOne)} {searchprompt.toLocaleLowerCase()} {formatMessage(messages.listviewUploadOne)}
              </span>
            )}
          </div>
        )}
        {filteredItems.length > 0 && (
          <List
          //  className={classes.root}
          >
            <Typography style={{ float: "left", paddingLeft: "2px" }}>
              {formatMessage(messages.listviewCount)} {title.toLocaleLowerCase()}: {items.length}
            </Typography>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <Button
                style={{ borderColor: "lightGrey", color: sort.type === SortEnum.ALPHABETICAL ? "#386fa3" : "grey", paddingTop: 5 }}
                size="small"
                variant="outlined"
                onClick={() =>
                  setSort({
                    type: SortEnum.ALPHABETICAL,
                    sortDirection: getSort(SortEnum.ALPHABETICAL, sort),
                  })
                }
              >
                A-Z
                {getSortDirectionIcons({ type: SortEnum.ALPHABETICAL, sortDirection: sort.sortDirection })}
              </Button>
              <Button
                size="small"
                variant="outlined"
                style={{ borderColor: "lightGrey", color: sort.type === SortEnum.DATE_CREATED ? "#386fa3" : "grey", marginLeft: "14px", paddingTop: 5 }}
                onClick={() =>
                  setSort({
                    type: SortEnum.DATE_CREATED,
                    sortDirection: getSort(SortEnum.DATE_CREATED, sort),
                  })
                }
              >
                {formatMessage(messages.listviewDateCreated)}
                {getSortDirectionIcons({ type: SortEnum.DATE_CREATED, sortDirection: sort.sortDirection })}
              </Button>
              {hasDateModified && (
                <Button
                  size="small"
                  variant="outlined"
                  style={{ borderColor: "lightGrey", color: sort.type === SortEnum.DATE_MODIFIED ? "#386fa3" : "grey", marginLeft: "14px", paddingTop: 5 }}
                  onClick={() =>
                    setSort({
                      type: SortEnum.DATE_MODIFIED,
                      sortDirection: getSort(SortEnum.DATE_MODIFIED, sort),
                    })
                  }
                >
                  {formatMessage(messages.listviewDateModified)}
                  {getSortDirectionIcons({ type: SortEnum.DATE_MODIFIED, sortDirection: sort.sortDirection })}
                </Button>
              )}
            </Box>
            {filteredItems.map((item) => {
              const labelId = `checkbox-list-label-${item.id}`;

              return (
                <ListItem
                  key={item.id}
                  role={undefined}
                  dense
                  button
                  onClick={() => {
                    push(`${path}/${item.id}`);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="test" src={item.avatarUrl === "" ? `${window.env.API_URL}images/orbit.logo.png` : item.avatarUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    id={labelId}
                    primary={
                      <Fragment>
                        <Typography style={{ display: "inline-block" }} variant="h6">
                          {item.name}
                        </Typography>
                        {hasStatus ? <Tooltip title={item.status}>{getStatusColor(item.statusColor)}</Tooltip> : <>{getStatusColor(item.statusColor)}</>}
                      </Fragment>
                    }
                    secondary={<Typography variant="caption">{getItemMetaLabel({ item, intl })}</Typography>}
                  />
                  {/* {[LayerGroupStatus.FINISHED, LayerGroupStatus.ERROR].includes(map.status) && ( */}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={(e) => {
                        //@ts-ignore
                        setAnchorEl(e.target);
                        setActiveLayerIdMenu(item.id);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                  {/* )} */}
                </ListItem>
              );
            })}
          </List>
        )}
        <CustomDialogView
          open={dialogOpen}
          maxWidth={"sm"}
          handleClose={() => {
            closeDialog();
          }}
          dialogTitle={formatMessage(messages.listviewDeleteLowercase, { singleItemName: singleItemName })}
          dialogContent={
            <Box>
              <Typography> {formatMessage(messages.listviewDeleteQuestion, { singleItemName: singleItemName })}</Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  marginTop: "15px",
                }}
              >
                <Button id="submit" onClick={closeDialog}>
                  {formatMessage(messages.listviewCancel)}
                </Button>
                <Button
                  id="submit"
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    handleDelete?.(activeLayerIdMenu);
                    closeDialog();
                  }}
                  disableElevation
                >
                  {formatMessage(messages.listviewDelete)}
                </Button>
              </Box>
            </Box>
          }
        />
      </>
    );
  },
);

export default injectIntl(ListView);
